


































































































































































































import { Getter, State } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import AuthModule from '@/app/auth/store';
import UserDto from '@/app/user/dto/user.dto';
import MoneyView from '@/app/core/components/money-view.vue';
import ReservationDto from '../dto/reservation.dto';
import ReservationChannel from './reservation-channel.vue';

@Component({
  components: {
    MoneyView,
    ReservationChannel,
  },
})
export default class ReservationInfo extends Vue {
  @State((state) => state.user)
  private user!: UserDto;

  @Prop({ type: Object, required: true })
  private reservation!: ReservationDto;

  @Getter
  private isHost!: typeof AuthModule.prototype.isHost;

  get isBlocking() {
    return this.reservation?.channel?.name === 'Blocked channel';
  }

  get isOwner() {
    return this.reservation?.owner?.id === this.user.id;
  }

  get adults() {
    return this.reservation.adults ?? 0;
  }

  get children() {
    return this.reservation.children ?? 0;
  }
}
