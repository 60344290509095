

















































import { Mixins, Component } from 'vue-property-decorator';
import { required } from '@/app/core/validation';
import FormMixin from '@/app/core/mixins/form.mixin';
import { ReservationMessageDto } from '../dto/reservation.dto';

@Component
export default class SendMessageForm extends Mixins(FormMixin) {
  protected data: ReservationMessageDto = {
    subject: '',
    text: '',
    html: '',
  };

  protected rules = {
    text: [required],
  };

  afterSubmit() {
    this.data = {
      subject: '',
      text: '',
      html: '',
    };
    this.resetValidation();
  }
}
