















import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import SendMessageForm from './send-message-form.vue';
import ReservationMessage from './reservation-message.vue';
import reservationService from '../services/reservations.service';
import { ReservationMessageDto, ReservationMessageType } from '../dto/reservation.dto';

@Component({
  components: {
    SendMessageForm,
    ReservationMessage,
  },
})
export default class ReservationMessages extends HandlesErrorMixin {
  private loading = 0;
  private messages: ReservationMessageDto[] = [];

  @Prop({ type: String, required: true })
  private reservationId!: string;

  @Watch('reservationId', { immediate: true })
  async loadMessages() {
    this.setLoading(1);

    try {
      const res = await reservationService.getReservationMessages(this.reservationId);

      this.messages = res.data.messages;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(-1);
    }
  }

  @Emit('setLoading')
  setLoading(loading: number) {
    this.loading += loading;

    return loading;
  }

  async sendMessage(message: ReservationMessageDto) {
    this.loading += 1;
    const fakeId = `message-${Math.random()}`;
    const sendingMessage = {
      id: fakeId,
      sending: true,
      type: ReservationMessageType.Outbox,
      ...message,
    };

    try {
      this.messages.splice(0, 0, sendingMessage);
      await reservationService.sendMessage(
        this.reservationId,
        message,
      );
      sendingMessage.sending = false;
      this.$notify.success(this.$t('success.messageSent').toString());
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading -= 1;
    }
  }
}
