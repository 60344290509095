


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { ReservationMessageDto } from '../dto/reservation.dto';

@Component
export default class ReservationMessage extends Vue {
  @Prop({ type: Boolean, default: false })
  private loading!: boolean;

  @Prop({ type: Object, required: true })
  private message!: ReservationMessageDto;
}
