import api from '@/app/core/services/api.service';
import ReservationsDto from '../dto/reservations.dto';
import GetReservationsDto from '../dto/get-reservations.dto';
import ReservationEventDto from '../dto/reservation-event.dto';
import ListReservationsDto from '../dto/list-reservations.dto';
import ReservationCheckDto from '../dto/reservation-check.dto';
import ReservationMessagesDto from '../dto/reservation-messages.dto';
import GetReservationCheckDto from '../dto/get-reservation-checks.dto';
import GetReservationEventsDto from '../dto/get-reservation-events.dto';
import PaginatedReservationsDto from '../dto/paginated-reservations.dto';
import ReservationDto, { ReservationMessageDto } from '../dto/reservation.dto';
import GetReservationChecksCountDto from '../dto/get-reservation-checks-count.dto';

export const getReservationCheck = (params: GetReservationCheckDto) => (
  api.get<ReservationCheckDto>('/reservations/check', { params })
);

export const getReservationChecksCount = (params: GetReservationChecksCountDto) => (
  api.get<{ count: number }>('/reservations/check/count', { params })
);

export const getReservationEvent = (params: GetReservationEventsDto) => (
  api.get<ReservationEventDto>('/reservations/event', { params })
);

export const getReservationEventsCount = () => (
  api.get<{ count: number }>('/reservations/event/count')
);

export const getReservations = (params: GetReservationsDto) => (
  api.get<ReservationsDto>('/reservations/calendar', { params })
);

const listReservations = (params: ListReservationsDto) => (
  api.get<PaginatedReservationsDto>('/reservations', { params })
);

export const createReservation = (dto: ReservationDto) => (
  api.post<ReservationDto>('/reservations', dto)
);

export const createFromBlocking = (id: string, dto: Partial<ReservationDto>) => (
  api.put<ReservationDto>(`/reservations/${id}/create-from-blocking`, dto)
);

export const getReservation = (id: string) => (
  api.get<ReservationDto>(`/reservations/${id}`)
);

const getReservationMessages = (id: string) => (
  api.get<ReservationMessagesDto>(`/reservations/${id}/messages`)
);

const updateReservation = (id: string, dto: ReservationDto) => (
  api.put<ReservationDto>(`/reservations/${id}`, dto)
);

export const cancelReservation = (id: string) => (
  api.put(`/reservations/${id}/cancel`)
);

const deleteReservation = (id: string) => (
  api.delete(`/reservations/${id}`)
);

const sendMessage = (id: string, dto: ReservationMessageDto) => (
  api.post<ReservationMessageDto>(`/reservations/${id}/messages`, dto)
);

export default {
  sendMessage,
  getReservation,
  getReservations,
  listReservations,
  deleteReservation,
  cancelReservation,
  createReservation,
  updateReservation,
  createFromBlocking,
  getReservationMessages,
};
