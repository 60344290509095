


























































import { Component, Prop, Vue } from 'vue-property-decorator';
import MoneyView from '@/app/core/components/money-view.vue';
import ReservationDto, { PriceElementType, ReservationPriceElementDto } from '../dto/reservation.dto';
import ReservationChannel from './reservation-channel.vue';

@Component({
  components: {
    MoneyView,
    ReservationChannel,
  },
})
export default class ReservationPayment extends Vue {
  @Prop({ type: Object, required: true })
  private reservation!: ReservationDto;

  get priceElements() {
    return this.reservation.priceElements?.filter(this.channelFilter);
  }

  get channelFilter() {
    switch (this.reservation?.channel?.name) {
      case 'Airbnb':
        return this.airbnbFilter;
      case 'Booking.com':
        return this.bookingFilter;
      default:
        return () => true;
    }
  }

  bookingFilter(priceElement: ReservationPriceElementDto) {
    const whiteList: Array<string> = [
      PriceElementType.BasePrice,
      PriceElementType.CleaningFee,
    ];
    const channelCustomWhiteList: Array<string> = [
      'Impuesto municipal',
      'IVA',
    ];

    if (whiteList.includes(priceElement.type)) return true;

    if (priceElement.type === PriceElementType.ChannelCustom) {
      return channelCustomWhiteList.includes(priceElement.name);
    }

    return false;
  }

  airbnbFilter(priceElement: ReservationPriceElementDto) {
    const whiteList: Array<string> = [
      PriceElementType.BasePrice,
      PriceElementType.CleaningFee,
      PriceElementType.Commission,
    ];
    const channelCustomWhiteList: Array<string> = [
      'Cancellation Payout',
    ];

    if (whiteList.includes(priceElement.type)) return true;

    if (priceElement.type === PriceElementType.ChannelCustom) {
      return channelCustomWhiteList.includes(priceElement.name);
    }

    return false;
  }
}
