













































































































import { Getter, State } from 'vuex-class';
import { Component, Watch } from 'vue-property-decorator';
import AuthModule from '@/app/auth/store';
import UserDto from '@/app/user/dto/user.dto';
import ApartmentDto from '@/app/apartments/dto/apartment.dto';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import ConfirmationDialog from '@/app/core/components/confirmation-dialog.vue';
import ReservationInfo from '../components/reservation-info.vue';
import ReservationPayment from '../components/reservation-payment.vue';
import reservationsService, { getReservation, cancelReservation } from '../services/reservations.service';
import ReservationMessages from '../components/reservation-messages.vue';
import ReservationDto, { ReservationType } from '../dto/reservation.dto';

@Component({
  metaInfo(this: ViewReservation) {
    return { title: this.$t('pageTitle.reservationDetails').toString() };
  },
  components: {
    ReservationInfo,
    ReservationPayment,
    ReservationMessages,
  },
})
export default class ViewReservation extends HandlesErrorMixin {
  private tab = 0;
  private loading = 0;
  private cancelling = false;
  private reservationId: string | null = null;
  private reservation: ReservationDto | null = null;

  @State((state) => state.user)
  private user!: UserDto;

  @Getter
  private isHost!: typeof AuthModule.prototype.isHost;

  @Getter
  private isHostAdmin!: typeof AuthModule.prototype.isHostAdmin;

  get isDeleteable() {
    return this.isHostAdmin;
  }

  get isCancellable() {
    const can = this.isHostAdmin || this.isOwner;
    const cancellable = (this.isBlocking || this.isFromHosttate) && !this.isCancelled;

    return cancellable && can;
  }

  get isOwner() {
    return this.reservation?.owner?.id === this.user.id;
  }

  get isFromHosttate() {
    return this.reservation?.channel?.name === 'Homepage';
  }

  get isBlocking() {
    return this.reservation?.channel?.name === 'Blocked channel';
  }

  get isCancelled() {
    return this.reservation?.type === ReservationType.Cancellation;
  }

  @Watch('tab')
  onTabChange() {
    let tab;

    switch (this.tab) {
      case 1:
        tab = 'payment';
        break;
      case 2:
        tab = 'messages';
        break;
      default:
        tab = '';
    }

    if (this.$route.params.tab !== tab) {
      this.$router.push(`/reservations/details/${this.reservationId}/${tab}`);
    }
  }

  setLoading(loading: number) {
    this.loading += loading;
  }

  async onDelete() {
    const apartment = (this.reservation?.apartment as ApartmentDto).name;
    const title = this.$t('title.deleteReservation');
    const message = this.$t('message.confirmDeleteReservation', { apartment });
    const confirm = await this.$dialog.open(ConfirmationDialog, { title, message });

    if (confirm) {
      try {
        await reservationsService.deleteReservation(this.reservationId as string);
        this.$router.push('/reservations');
        this.$notify.success(this.$t('success.reservationDeleted').toString());
      } catch (err) {
        this.handleError(err);
      }
    }
  }

  async onCancel() {
    const apartment = (this.reservation?.apartment as ApartmentDto).name;
    const title = this.$t('title.cancelReservation');
    const message = this.$t('message.confirmCancelReservation', { apartment });
    const confirm = await this.$dialog.open(ConfirmationDialog, { title, message });

    if (confirm) {
      this.cancelling = true;
      try {
        await cancelReservation(this.reservationId as string);
        this.getReservation();
        this.$notify.success(this.$t('success.reservationCancelled').toString());
      } catch (err) {
        this.handleError(err);
      } finally {
        this.cancelling = false;
      }
    }
  }

  updateReservation() {
    if (!this.reservationId) return;

    this.$router.push({
      name: 'update-reservation',
      params: { id: this.reservationId },
      query: { redirect: this.$route.fullPath },
    });
  }

  async getReservation() {
    if (!this.reservationId) return;

    this.loading += 1;

    try {
      const response = await getReservation(this.reservationId);
      this.reservation = response.data;
    } catch (error) {
      if (this.isNotFoundError(error)) {
        this.$router.push({ name: '404' });
      } else {
        this.handleError(error);
      }
    } finally {
      this.loading -= 1;
    }
  }

  created() {
    this.reservationId = this.$route.params.id;
    this.getReservation();

    const { tab } = this.$route.params;
    switch (tab) {
      case 'messages':
        this.tab = 2;
        break;
      case 'payment':
        this.tab = 1;
        break;
      default:
        this.tab = 0;
    }
  }
}
